import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faComment } from "@fortawesome/free-solid-svg-icons/faComment";
import { faFireAlt } from "@fortawesome/free-solid-svg-icons/faFireAlt";
import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faSortAlphaDown } from "@fortawesome/free-solid-svg-icons/faSortAlphaDown";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons/faTachometerAlt";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faArrowAltUp } from "@fortawesome/pro-solid-svg-icons/faArrowAltUp";
import { faMicrophone } from "@fortawesome/pro-solid-svg-icons/faMicrophone";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons/faSparkles";
import invert from "lodash/invert";

import faBoltCircle from "styles/icons/faBoltCircle";
import faFollow from "styles/icons/faFollow";
import faList from "styles/icons/faList";
import faReachAll from "styles/icons/faReachAll";
export const SORT_ORDER_ALPHABETICAL = "SORT_ORDER_ALPHABETICAL";
export const SORT_ORDER_BEST_OPTION = "SORT_ORDER_BEST_OPTION";
export const SORT_ORDER_CURATED = "SORT_ORDER_CURATED";
export const SORT_ORDER_SPONSOR_EPISODE_COUNT =
  "SORT_ORDER_SPONSOR_EPISODE_COUNT";
export const SORT_ORDER_SPONSOR_AD_SPEND_HIGH =
  "SORT_ORDER_SPONSOR_AD_SPEND_HIGH";
export const SORT_ORDER_FOLLOWED_DATE = "SORT_ORDER_FOLLOWED_DATE";
export const SORT_ORDER_NEW = "SORT_ORDER_NEW";
export const SORT_ORDER_PODCAST_RANK = "SORT_ORDER_PODCAST_RANK";
export const SORT_ORDER_POPULAR = "SORT_ORDER_POPULAR";
export const SORT_ORDER_POWER_SCORE = "SORT_ORDER_POWER_SCORE";
export const SORT_ORDER_RANDOM = "SORT_ORDER_RANDOM";
export const SORT_ORDER_RANKING = "SORT_ORDER_RANKING";
export const SORT_ORDER_RANKING_MONTH = "SORT_ORDER_RANKING_MONTH";
export const SORT_ORDER_RANKING_WEEK = "SORT_ORDER_RANKING_WEEK";
export const SORT_ORDER_RANKING_YEAR = "SORT_ORDER_RANKING_YEAR";
export const SORT_ORDER_RATED_DATE = "SORT_ORDER_RATED_DATE";
export const SORT_ORDER_RECENT = "SORT_ORDER_RECENT";
export const SORT_ORDER_RECENT_WEIGHTED = "SORT_ORDER_RECENT_WEIGHTED";
export const SORT_ORDER_MONTHLY_REACH = "SORT_ORDER_MONTHLY_REACH";
export const SORT_LAST_7_DAYS = "SORT_LAST_7_DAYS";
export const SORT_LAST_30_DAYS = "SORT_LAST_30_DAYS";
export const SORT_ORDER_RELEVANCE = "SORT_ORDER_RELEVANCE";
export const SORT_ORDER_SAVED_DATE = "SORT_ORDER_SAVED_DATE";
export const SORT_ORDER_TOP = "SORT_ORDER_TOP";
export const SORT_ORDER_TRENDING = "SORT_ORDER_TRENDING";
export const SORT_ORDER_USER_VALUE = "SORT_ORDER_USER_VALUE";
export const SORT_ORDER_DEADLINE = "SORT_ORDER_DEADLINE";

export const SORT_ORDER_VARIABLE_ATTRIBUTE = "SORT_ORDER_VARIABLE_ATTRIBUTE";
export const SORT_ORDER_VARIABLE_ATTRIBUTE_EPISODE_CREDIT_COUNT =
  "SORT_ORDER_VARIABLE_ATTRIBUTE_EPISODE_CREDIT_COUNT";
export const SORT_ORDER_VARIABLE_ATTRIBUTE_FOLLOW_COUNT =
  "SORT_ORDER_VARIABLE_ATTRIBUTE_FOLLOW_COUNT";
export const SORT_ORDER_VARIABLE_ATTRIBUTE_FOLLOWER_COUNT =
  "SORT_ORDER_VARIABLE_ATTRIBUTE_FOLLOWER_COUNT";
export const SORT_ORDER_VARIABLE_ATTRIBUTE_PUBLIC_LIST_COUNT =
  "SORT_ORDER_VARIABLE_ATTRIBUTE_PUBLIC_LIST_COUNT";
export const SORT_ORDER_VARIABLE_ATTRIBUTE_RATING_COUNT =
  "SORT_ORDER_VARIABLE_ATTRIBUTE_RATING_COUNT";
export const SORT_ORDER_VARIABLE_ATTRIBUTE_REVIEW_COUNT =
  "SORT_ORDER_VARIABLE_ATTRIBUTE_REVIEW_COUNT";

export const SORT_ORDER_NETWORK_PODCAST_COUNT =
  "SORT_ORDER_NETWORK_PODCAST_COUNT";

export const keysToConstants = {
  ranking: SORT_ORDER_RANKING,
  week: SORT_ORDER_RANKING_WEEK,
  month: SORT_ORDER_RANKING_MONTH,
  monthlyReach: SORT_ORDER_MONTHLY_REACH,
  year: SORT_ORDER_RANKING_YEAR,
  recent: SORT_ORDER_RECENT,
  recentw: SORT_ORDER_RECENT_WEIGHTED,
  last_7days: SORT_LAST_7_DAYS,
  last_30days: SORT_LAST_30_DAYS,
  trending: SORT_ORDER_TRENDING,
  popular: SORT_ORDER_POPULAR,
  alpha: SORT_ORDER_ALPHABETICAL,
  relevance: SORT_ORDER_RELEVANCE,
  rated_date: SORT_ORDER_RATED_DATE,
  saved_date: SORT_ORDER_SAVED_DATE,
  best: SORT_ORDER_CURATED,
  random: SORT_ORDER_RANDOM,
  user: SORT_ORDER_USER_VALUE,
  followed_date: SORT_ORDER_FOLLOWED_DATE,
  power: SORT_ORDER_POWER_SCORE,
  top: SORT_ORDER_TOP,
  new: SORT_ORDER_NEW,
  attribute: SORT_ORDER_VARIABLE_ATTRIBUTE,
  appearanceCount: SORT_ORDER_VARIABLE_ATTRIBUTE_EPISODE_CREDIT_COUNT,
  followCount: SORT_ORDER_VARIABLE_ATTRIBUTE_FOLLOW_COUNT,
  followerCount: SORT_ORDER_VARIABLE_ATTRIBUTE_FOLLOWER_COUNT,
  listCount: SORT_ORDER_VARIABLE_ATTRIBUTE_PUBLIC_LIST_COUNT,
  ratingCount: SORT_ORDER_VARIABLE_ATTRIBUTE_RATING_COUNT,
  reviewCount: SORT_ORDER_VARIABLE_ATTRIBUTE_REVIEW_COUNT,
  podcastCount: SORT_ORDER_NETWORK_PODCAST_COUNT,
};

export const constantsToKeys = invert(keysToConstants);

export const sortKeyRegex = Object.keys(keysToConstants).join("|");

export const RANKING_SORTS = [
  SORT_ORDER_RANKING,
  SORT_ORDER_RANKING_WEEK,
  SORT_ORDER_RANKING_MONTH,
  SORT_ORDER_RANKING_YEAR,
];

export const sortIcons = [
  {
    id: SORT_ORDER_RECENT_WEIGHTED,
    icon: faClock,
    title: "By Date",
    defaultDirection: "desc",
    directionIcon: "numeric",
  },
  {
    id: SORT_LAST_7_DAYS,
    icon: faClock,
    title: "Last 7 Days",
    defaultDirection: "desc",
    directionIcon: "numeric",
  },
  {
    id: SORT_LAST_30_DAYS,
    icon: faClock,
    title: "Last 30 Days",
    defaultDirection: "desc",
    directionIcon: "numeric",
  },
  {
    id: SORT_ORDER_RECENT,
    icon: faClock,
    title: "By Date",
    defaultDirection: "desc",
    directionIcon: "numeric",
  },
  {
    id: SORT_ORDER_TRENDING,
    icon: faFireAlt,
    title: "Trending",
  },
  {
    id: SORT_ORDER_RANKING,
    icon: faStar,
    title: "By Rating",
    defaultDirection: "desc",
    directionIcon: "numeric",
  },
  {
    id: SORT_ORDER_MONTHLY_REACH,
    icon: faReachAll,
    title: "Monthly Reach",
    defaultDirection: "desc",
    directionIcon: "numeric",
  },
  {
    id: SORT_ORDER_POPULAR,
    icon: faTachometerAlt,
    title: "By Popularity",
    defaultDirection: "desc",
    directionIcon: "numeric",
  },
  {
    id: SORT_ORDER_ALPHABETICAL,
    icon: faSortAlphaDown,
    title: "Alphabetical",
    defaultDirection: "asc",
    directionIcon: "alpha",
  },
  {
    id: SORT_ORDER_RELEVANCE,
    icon: faSearch,
    title: "Relevancy",
  },
  {
    id: SORT_ORDER_RATED_DATE,
    icon: faClock,
    title: "By Date Rated",
    defaultDirection: "desc",
    directionIcon: "amount",
  },
  {
    id: SORT_ORDER_SAVED_DATE,
    icon: faClock,
    title: "By Date Saved",
    defaultDirection: "desc",
    directionIcon: "amount",
  },
  {
    id: SORT_ORDER_FOLLOWED_DATE,
    icon: faClock,
    title: "By Date Followed",
  },
  {
    id: SORT_ORDER_CURATED,
    icon: faHeart,
    title: "Best",
  },
  {
    id: SORT_ORDER_USER_VALUE,
    title: "My Rating",
    icon: faStar,
  },
  {
    id: SORT_ORDER_POWER_SCORE,
    title: "Power Score",
    icon: faBoltCircle,
  },
  {
    id: SORT_ORDER_TOP,
    title: "Top",
    icon: faArrowAltUp,
  },
  {
    id: SORT_ORDER_NEW,
    title: "New",
    icon: faSparkles,
  },
  {
    id: SORT_ORDER_VARIABLE_ATTRIBUTE_EPISODE_CREDIT_COUNT,
    title: "Credits",
    icon: faMicrophone,
    sortProps: {
      options: { count_attribute: "episode_credit" },
    },
  },
  {
    id: SORT_ORDER_VARIABLE_ATTRIBUTE_FOLLOW_COUNT,
    title: "Follows",
    icon: faFollow,
    sortProps: {
      options: { count_attribute: "follow" },
    },
  },
  {
    id: SORT_ORDER_VARIABLE_ATTRIBUTE_FOLLOWER_COUNT,
    title: "Followers",
    icon: faUsers,
    sortProps: {
      options: { count_attribute: "follower" },
    },
  },
  {
    id: SORT_ORDER_VARIABLE_ATTRIBUTE_PUBLIC_LIST_COUNT,
    title: "Lists",
    icon: faList,
    sortProps: {
      options: { count_attribute: "public_list" },
    },
  },
  {
    id: SORT_ORDER_VARIABLE_ATTRIBUTE_RATING_COUNT,
    title: "Ratings",
    icon: faStar,
    sortProps: {
      options: { count_attribute: "rating" },
    },
  },
  {
    id: SORT_ORDER_VARIABLE_ATTRIBUTE_REVIEW_COUNT,
    title: "Reviews",
    icon: faComment,
    sortProps: {
      options: { count_attribute: "review" },
    },
  },
  {
    id: SORT_ORDER_NETWORK_PODCAST_COUNT,
    icon: faMicrophone,
    title: "Total Podcast Countt",
    defaultDirection: "desc",
    directionIcon: "numeric",
  },
];

export const getSortConfig = (sort) =>
  sortIcons.find((config) => config.id === sort);

export const getSortIcon = (id) => sortIcons.find((config) => config.id === id);

export const allowedEntitySorts = {
  users: [
    SORT_ORDER_POPULAR,
    SORT_ORDER_ALPHABETICAL,
    SORT_ORDER_RELEVANCE,
    // SORT_ORDER_FOLLOWED_DATE,
    SORT_ORDER_VARIABLE_ATTRIBUTE,
    SORT_ORDER_VARIABLE_ATTRIBUTE_FOLLOW_COUNT,
    SORT_ORDER_VARIABLE_ATTRIBUTE_PUBLIC_LIST_COUNT,
    SORT_ORDER_VARIABLE_ATTRIBUTE_RATING_COUNT,
    SORT_ORDER_VARIABLE_ATTRIBUTE_REVIEW_COUNT,
  ],
  podcasts: [
    SORT_ORDER_USER_VALUE,
    SORT_ORDER_CURATED,
    SORT_ORDER_RATED_DATE,
    SORT_ORDER_RELEVANCE,
    SORT_ORDER_ALPHABETICAL,
    SORT_ORDER_POPULAR,
    ...RANKING_SORTS,
    SORT_ORDER_TRENDING,
    SORT_ORDER_RECENT,
    SORT_ORDER_RECENT_WEIGHTED,
    SORT_ORDER_POWER_SCORE,
    SORT_ORDER_TOP,
    // SORT_ORDER_FOLLOWED_DATE,
    SORT_ORDER_NEW,
  ],
  episodes: [
    SORT_ORDER_USER_VALUE,
    SORT_ORDER_CURATED,
    SORT_ORDER_RATED_DATE,
    SORT_ORDER_RELEVANCE,
    SORT_ORDER_ALPHABETICAL,
    SORT_ORDER_POPULAR,
    ...RANKING_SORTS,
    SORT_ORDER_TRENDING,
    SORT_ORDER_RECENT,
    SORT_ORDER_RECENT_WEIGHTED,
    SORT_ORDER_NEW,
    SORT_ORDER_POWER_SCORE,
  ],
  lists: [
    SORT_ORDER_USER_VALUE,
    SORT_ORDER_CURATED,
    SORT_ORDER_RATED_DATE,
    SORT_ORDER_RELEVANCE,
    SORT_ORDER_POPULAR,
    ...RANKING_SORTS,
    SORT_ORDER_TRENDING,
    SORT_ORDER_RECENT,
    SORT_ORDER_RECENT_WEIGHTED,
    // SORT_ORDER_FOLLOWED_DATE,
  ],
  creators: [
    SORT_ORDER_CURATED,
    SORT_ORDER_RATED_DATE,
    SORT_ORDER_RELEVANCE,
    SORT_ORDER_ALPHABETICAL,
    SORT_ORDER_POPULAR,
    SORT_ORDER_TRENDING,
    SORT_ORDER_VARIABLE_ATTRIBUTE,
    SORT_ORDER_VARIABLE_ATTRIBUTE_FOLLOWER_COUNT,
    SORT_ORDER_VARIABLE_ATTRIBUTE_EPISODE_CREDIT_COUNT,
    // SORT_ORDER_FOLLOWED_DATE,
  ],
  reviews: [
    SORT_ORDER_USER_VALUE,
    SORT_ORDER_CURATED,
    SORT_ORDER_RATED_DATE,
    SORT_ORDER_RELEVANCE,
    SORT_ORDER_POPULAR,
    ...RANKING_SORTS,
    SORT_ORDER_TRENDING,
    SORT_ORDER_RECENT,
    SORT_ORDER_RECENT_WEIGHTED,
  ],
  networks: [
    SORT_ORDER_ALPHABETICAL,
    SORT_ORDER_RELEVANCE,
    SORT_ORDER_NETWORK_PODCAST_COUNT,
    SORT_ORDER_MONTHLY_REACH,
  ],
};

export const sortsRequireProPermission = [SORT_ORDER_POWER_SCORE];
